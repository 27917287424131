<template>
  <section
    v-if="invoiceData"
    class="invoice-preview-wrapper"
  >
    <div
      id="exportArea"
    >
      <div class="d-none row page-break Justify-content-center">
        <div class="col-4">
          <!-- Header -->
          <div class="invoice-padding pb-0 mb-1">
            <div class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div class="text-center">
                <div
                  class="logo-wrapper "
                  :style="{ marginBottom: '0px' }"
                >
                  <b-img
                    fluid
                    :src="apbnLogo"
                    alt="Fems"
                    :style="{ height: '40px' }"
                  />
                  <b>
                    Armed Police Battalion Public School & College
                  </b>
                </div>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Uposhohor Rd, Nishindara, Bogra 5800
                </p>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Payment Invoice (Bank copy) for July 2021
                </p>
                <p
                  class="card-text mb-0"
                  :style="{lineHeight:'10px'}"
                >
                  Last date of payment (without Fine): <b>25th March 2021</b>
                </p>
              </div>

            </div>
          </div>
          <table id="printTable">
            <tr>
              <td>Name</td>
              <td colspan="3">
                <b>Sadikul Islam</b>
              </td>
            </tr>
            <tr>
              <td>Student Id</td>
              <td><b>1000000000</b></td>
              <td>Class</td>
              <td><b>Eight</b></td>
            </tr>
            <tr>
              <td>Section</td>
              <td><b>Jupiter</b></td>
              <td>Roll</td>
              <td><b>05</b></td>
            </tr>
          </table>
          <!-- Invoice Description: Table -->
          <table
            id="printTable"
            class="due-table"
          >
            <thead class="text-center">
              <tr>
                <th scope="col">
                  SL
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoiceData"
                :key="invoice.id"
              >
                <th
                  scope="row"
                  class="text-center"
                >
                  {{ invoice.id }}
                </th>
                <td>{{ invoice.description }}</td>
                <td class="text-center">
                  {{ invoice.amount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Total
                </th>
                <th scope="col">
                  12835.00
                </th>
              </tr>
            </tfoot>
          </table>
          <!-- Note -->
          <div class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <p
              :style="{marginTop:'5px', marginBottom:'0px'}"
            >
              <small>
                1. Please pay your tuition fees to Padma Bank Bogra.
              </small>
            </p>
            <p>
              <small>
                2. Equivalent amount of one month fee will be added as readmission fee after
                last day of payment date
              </small>
            </p>
          </div>
          <!-- Invoice Footer -->
          <div class="invoice-padding pb-0 mt-1">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div class="mb-0">
                  <b>Print Data:</b>
                  <span class="ml-75">: 07-07-2021</span>
                </div>
                <div class="mb-0">
                  <b>Printed By</b>
                  <span class="ml-75">:  2001254785</span>
                </div>
                <div class="mb-0">
                  <b>Powered BY</b>
                  <span class="ml-75">:  fitl.com.bd</span>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper border-dark text-center">
                  <p>(Signature)</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="col-4">
          <!-- Header -->
          <div class="invoice-padding pb-0 mb-1">
            <div class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div class="text-center">
                <div
                  class="logo-wrapper "
                  :style="{ marginBottom: '0px' }"
                >
                  <b-img
                    fluid
                    :src="apbnLogo"
                    alt="Fems"
                    :style="{ height: '40px' }"
                  />
                  <b>
                    Armed Police Battalion Public School & College
                  </b>
                </div>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Uposhohor Rd, Nishindara, Bogra 5800
                </p>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Payment Invoice(School Copy) for July 2021
                </p>
                <p
                  class="card-text mb-0"
                  :style="{lineHeight:'10px'}"
                >
                  Last date of payment (without Fine): <b>25th March 2021</b>
                </p>
              </div>

            </div>
          </div>
          <table id="printTable">
            <tr>
              <td>Name</td>
              <td colspan="3">
                <b>Sadikul Islam</b>
              </td>
            </tr>
            <tr>
              <td>Student Id</td>
              <td><b>1000000000</b></td>
              <td>Class</td>
              <td><b>Eight</b></td>
            </tr>
            <tr>
              <td>Section</td>
              <td><b>Jupiter</b></td>
              <td>Roll</td>
              <td><b>05</b></td>
            </tr>
          </table>
          <!-- Invoice Description: Table -->
          <table
            id="printTable"
            class="due-table"
          >
            <thead class="text-center">
              <tr>
                <th scope="col">
                  SL
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoiceData"
                :key="invoice.id"
              >
                <th
                  scope="row"
                  class="text-center"
                >
                  {{ invoice.id }}
                </th>
                <td>{{ invoice.description }}</td>
                <td class="text-center">
                  {{ invoice.amount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Total
                </th>
                <th scope="col">
                  12835.00
                </th>
              </tr>
            </tfoot>
          </table>
          <!-- Note -->
          <div class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <p
              :style="{marginTop:'5px', marginBottom:'0px'}"
            >
              <small>
                1. Please pay your tuition fees to Padma Bank Bogra.
              </small>
            </p>
            <p>
              <small>
                2. Equivalent amount of one month fee will be added as readmission fee after
                last day of payment date
              </small>
            </p>
          </div>
          <!-- Invoice Footer -->
          <div class="invoice-padding pb-0 mt-1">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div class="mb-0">
                  <b>Print Data:</b>
                  <span class="ml-75">: 07-07-2021</span>
                </div>
                <div class="mb-0">
                  <b>Printed By</b>
                  <span class="ml-75">:  2001254785</span>
                </div>
                <div class="mb-0">
                  <b>Powered BY</b>
                  <span class="ml-75">:  fitl.com.bd</span>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper border-dark text-center">
                  <p>(Signature)</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="col-4">
          <!-- Header -->
          <div class="invoice-padding pb-0 mb-1">
            <div class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div class="text-center">
                <div
                  class="logo-wrapper "
                  :style="{ marginBottom: '0px' }"
                >
                  <b-img
                    fluid
                    :src="apbnLogo"
                    alt="Fems"
                    :style="{ height: '40px' }"
                  />
                  <b>
                    Armed Police Battalion Public School & College
                  </b>
                </div>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Uposhohor Rd, Nishindara, Bogra 5800
                </p>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Payment Invoice(Student copy) for July 2021
                </p>
                <p
                  class="card-text mb-0"
                  :style="{lineHeight:'10px'}"
                >
                  Last date of payment (without Fine): <b>25th March 2021</b>
                </p>
              </div>

            </div>
          </div>
          <table id="printTable">
            <tr>
              <td>Name</td>
              <td colspan="3">
                <b>Sadikul Islam</b>
              </td>
            </tr>
            <tr>
              <td>Student Id</td>
              <td><b>1000000000</b></td>
              <td>Class</td>
              <td><b>Eight</b></td>
            </tr>
            <tr>
              <td>Section</td>
              <td><b>Jupiter</b></td>
              <td>Roll</td>
              <td><b>05</b></td>
            </tr>
          </table>
          <!-- Invoice Description: Table -->
          <table
            id="printTable"
            class="due-table"
          >
            <thead class="text-center">
              <tr>
                <th scope="col">
                  SL
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoiceData"
                :key="invoice.id"
              >
                <th
                  scope="row"
                  class="text-center"
                >
                  {{ invoice.id }}
                </th>
                <td>{{ invoice.description }}</td>
                <td class="text-center">
                  {{ invoice.amount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Total
                </th>
                <th scope="col">
                  12835.00
                </th>
              </tr>
            </tfoot>
          </table>
          <!-- Note -->
          <div class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <p
              :style="{marginTop:'5px', marginBottom:'0px'}"
            >
              <small>
                1. Please pay your tuition fees to Padma Bank Bogra.
              </small>
            </p>
            <p>
              <small>
                2. Equivalent amount of one month fee will be added as readmission fee after
                last day of payment date
              </small>
            </p>
          </div>
          <!-- Invoice Footer -->
          <div class="invoice-padding pb-0 mt-1">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div class="mb-0">
                  <b>Print Data:</b>
                  <span class="ml-75">: 07-07-2021</span>
                </div>
                <div class="mb-0">
                  <b>Printed By</b>
                  <span class="ml-75">:  2001254785</span>
                </div>
                <div class="mb-0">
                  <b>Powered BY</b>
                  <span class="ml-75">:  fitl.com.bd</span>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper border-dark text-center">
                  <p>(Signature)</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="row mt-3 page-break Justify-content-center">
        <div class="col-4">
          <!-- Header -->
          <div class="invoice-padding pb-0 mb-1">
            <div class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div class="text-center">
                <div
                  class="logo-wrapper "
                  :style="{ marginBottom: '0px' }"
                >
                  <b-img
                    fluid
                    :src="apbnLogo"
                    alt="Fems"
                    :style="{ height: '40px' }"
                  />
                  <b>
                    Armed Police Battalion Public School & College
                  </b>
                </div>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Uposhohor Rd, Nishindara, Bogra 5800
                </p>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Payment Invoice (Bank copy) for July 2021
                </p>
                <p
                  class="card-text mb-0"
                  :style="{lineHeight:'10px'}"
                >
                  Last date of payment (without Fine): <b>25th March 2021</b>
                </p>
              </div>

            </div>
          </div>
          <table id="printTable">
            <tr>
              <td>Name</td>
              <td colspan="3">
                <b>Sadikul Islam</b>
              </td>
            </tr>
            <tr>
              <td>Student Id</td>
              <td><b>1000000000</b></td>
              <td>Class</td>
              <td><b>Eight</b></td>
            </tr>
            <tr>
              <td>Section</td>
              <td><b>Jupiter</b></td>
              <td>Roll</td>
              <td><b>05</b></td>
            </tr>
          </table>
          <!-- Invoice Description: Table -->
          <table
            id="printTable"
            class="due-table"
          >
            <thead class="text-center">
              <tr>
                <th scope="col">
                  SL
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoiceData"
                :key="invoice.id"
              >
                <th
                  scope="row"
                  class="text-center"
                >
                  {{ invoice.id }}
                </th>
                <td>{{ invoice.description }}</td>
                <td class="text-center">
                  {{ invoice.amount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Total
                </th>
                <th scope="col">
                  12835.00
                </th>
              </tr>
            </tfoot>
          </table>
          <!-- Note -->
          <div class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <p
              :style="{marginTop:'5px', marginBottom:'0px'}"
            >
              <small>
                1. Please pay your tuition fees to Padma Bank Bogra.
              </small>
            </p>
            <p>
              <small>
                2. Equivalent amount of one month fee will be added as readmission fee after
                last day of payment date
              </small>
            </p>
          </div>
          <!-- Invoice Footer -->
          <div class="invoice-padding pb-0 mt-1">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div class="mb-0">
                  <b>Print Data:</b>
                  <span class="ml-75">: 07-07-2021</span>
                </div>
                <div class="mb-0">
                  <b>Printed By</b>
                  <span class="ml-75">:  2001254785</span>
                </div>
                <div class="mb-0">
                  <b>Powered BY</b>
                  <span class="ml-75">:  fitl.com.bd</span>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper border-dark text-center">
                  <p>(Signature)</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="col-4">
          <!-- Header -->
          <div class="invoice-padding pb-0 mb-1">
            <div class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div class="text-center">
                <div
                  class="logo-wrapper "
                  :style="{ marginBottom: '0px' }"
                >
                  <b-img
                    fluid
                    :src="apbnLogo"
                    alt="Fems"
                    :style="{ height: '40px' }"
                  />
                  <b>
                    Armed Police Battalion Public School & College
                  </b>
                </div>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Uposhohor Rd, Nishindara, Bogra 5800
                </p>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Payment Invoice(School Copy) for July 2021
                </p>
                <p
                  class="card-text mb-0"
                  :style="{lineHeight:'10px'}"
                >
                  Last date of payment (without Fine): <b>25th March 2021</b>
                </p>
              </div>

            </div>
          </div>
          <table id="printTable">
            <tr>
              <td>Name</td>
              <td colspan="3">
                <b>Sadikul Islam</b>
              </td>
            </tr>
            <tr>
              <td>Student Id</td>
              <td><b>1000000000</b></td>
              <td>Class</td>
              <td><b>Eight</b></td>
            </tr>
            <tr>
              <td>Section</td>
              <td><b>Jupiter</b></td>
              <td>Roll</td>
              <td><b>05</b></td>
            </tr>
          </table>
          <!-- Invoice Description: Table -->
          <table
            id="printTable"
            class="due-table"
          >
            <thead class="text-center">
              <tr>
                <th scope="col">
                  SL
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoiceData"
                :key="invoice.id"
              >
                <th
                  scope="row"
                  class="text-center"
                >
                  {{ invoice.id }}
                </th>
                <td>{{ invoice.description }}</td>
                <td class="text-center">
                  {{ invoice.amount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Total
                </th>
                <th scope="col">
                  12835.00
                </th>
              </tr>
            </tfoot>
          </table>
          <!-- Note -->
          <div class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <p
              :style="{marginTop:'5px', marginBottom:'0px'}"
            >
              <small>
                1. Please pay your tuition fees to Padma Bank Bogra.
              </small>
            </p>
            <p>
              <small>
                2. Equivalent amount of one month fee will be added as readmission fee after
                last day of payment date
              </small>
            </p>
          </div>
          <!-- Invoice Footer -->
          <div class="invoice-padding pb-0 mt-1">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div class="mb-0">
                  <b>Print Data:</b>
                  <span class="ml-75">: 07-07-2021</span>
                </div>
                <div class="mb-0">
                  <b>Printed By</b>
                  <span class="ml-75">:  2001254785</span>
                </div>
                <div class="mb-0">
                  <b>Powered BY</b>
                  <span class="ml-75">:  fitl.com.bd</span>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper border-dark text-center">
                  <p>(Signature)</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="col-4">
          <!-- Header -->
          <div class="invoice-padding pb-0 mb-1">
            <div class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div class="text-center">
                <div
                  class="logo-wrapper "
                  :style="{ marginBottom: '0px' }"
                >
                  <b-img
                    fluid
                    :src="apbnLogo"
                    alt="Fems"
                    :style="{ height: '40px' }"
                  />
                  <b>
                    Armed Police Battalion Public School & College
                  </b>
                </div>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Uposhohor Rd, Nishindara, Bogra 5800
                </p>
                <p
                  class="card-text mb-25"
                  :style="{lineHeight:'10px'}"
                >
                  Payment Invoice(Student copy) for July 2021
                </p>
                <p
                  class="card-text mb-0"
                  :style="{lineHeight:'10px'}"
                >
                  Last date of payment (without Fine): <b>25th March 2021</b>
                </p>
              </div>

            </div>
          </div>
          <table id="printTable">
            <tr>
              <td>Name</td>
              <td colspan="3">
                <b>Sadikul Islam</b>
              </td>
            </tr>
            <tr>
              <td>Student Id</td>
              <td><b>1000000000</b></td>
              <td>Class</td>
              <td><b>Eight</b></td>
            </tr>
            <tr>
              <td>Section</td>
              <td><b>Jupiter</b></td>
              <td>Roll</td>
              <td><b>05</b></td>
            </tr>
          </table>
          <!-- Invoice Description: Table -->
          <table
            id="printTable"
            class="due-table"
          >
            <thead class="text-center">
              <tr>
                <th scope="col">
                  SL
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoiceData"
                :key="invoice.id"
              >
                <th
                  scope="row"
                  class="text-center"
                >
                  {{ invoice.id }}
                </th>
                <td>{{ invoice.description }}</td>
                <td class="text-center">
                  {{ invoice.amount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Total
                </th>
                <th scope="col">
                  12835.00
                </th>
              </tr>
            </tfoot>
          </table>
          <!-- Note -->
          <div class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <p
              :style="{marginTop:'5px', marginBottom:'0px'}"
            >
              <small>
                1. Please pay your tuition fees to Padma Bank Bogra.
              </small>
            </p>
            <p>
              <small>
                2. Equivalent amount of one month fee will be added as readmission fee after
                last day of payment date
              </small>
            </p>
          </div>
          <!-- Invoice Footer -->
          <div class="invoice-padding pb-0 mt-1">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div class="mb-0">
                  <b>Print Data:</b>
                  <span class="ml-75">: 07-07-2021</span>
                </div>
                <div class="mb-0">
                  <b>Printed By</b>
                  <span class="ml-75">:  2001254785</span>
                </div>
                <div class="mb-0">
                  <b>Powered BY</b>
                  <span class="ml-75">:  fitl.com.bd</span>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper border-dark text-center">
                  <p>(Signature)</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  BRow, BCol,
  VBToggle, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PDFGenerator from '@/@service/utils/PDFGenerator'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BImg,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    invoiceData: Array,
    // eslint-disable-next-line vue/require-default-prop
    apbnLogo: String,
  },
  methods: {
    generatePdf() {
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      const paperOrientation = 'l'
      const paperSize = 'A4'
      PDFGenerator.generatePDF(content, paperOrientation, paperSize)
      content.classList.add('d-none')
    },
    printInvoice() {
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      // window.open('', 'newwin', 'height=450, width=650,toolbar=no,menubar=no')
      window.print()
      content.classList.add('d-none')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  @page {
      size: landscape;
      margin:0;
  }
   .page-break { page-break-after: always; }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .invoice-preview{
    display:none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

}
#printTable {
  border: 2px solid black;
}
#printTable td, th {
  border: 1px solid black;
}
#printTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}
#exportArea{
  background: transparent;
  font-size: 10px;
  font-style:normal;
  color:black;
  padding:8px;
  width:1070px;
  height:740px;
//   background:white
}
</style>
