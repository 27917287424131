<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <!-- <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert> -->

    <!-- v-if="invoiceData" -->
    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <div
          no-body
          class="invoice-preview-card"
          :style="{background:&quot;white&quot;,padding:&quot;10px&quot;}"
        >
          <!-- Header -->
          <div class="invoice-padding pb-0">
            <div class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div class="text-center">
                <div
                  class="logo-wrapper "
                  :style="{ marginBottom: '0px' }"
                >
                  <b-img
                    fluid
                    :src="apbnLogo"
                    alt="Fems"
                    :style="{ height: '50px' }"
                  />
                  <h3>
                    Armed Police Battalion Public School & College
                  </h3>
                </div>
                <p class="card-text mb-25">
                  Uposhohor Rd, Nishindara, Bogra 5800
                </p>
                <p class="card-text mb-25">
                  Payment Invoice for July 2021
                </p>
                <p class="card-text mb-0">
                  Last date of payment (without Fine): 25th March 2021
                </p>
              </div>

            </div>
          </div>

          <!-- Spacer -->
          <hr class="invoice-spacing">
          <table class="table table-bordered">
            <tr>
              <td>Name</td>
              <td colspan="3">
                <b>Sadikul Islam</b>
              </td>
            </tr>
            <tr>
              <td>Student Id</td>
              <td><b>1000000000</b></td>
              <td>Class</td>
              <td><b>Eight</b></td>
            </tr>
            <tr>
              <td>Section</td>
              <td><b>Jupiter</b></td>
              <td>Roll</td>
              <td><b>05</b></td>
            </tr>
          </table>
          <!-- {{ JSON.stringify(invoiceData) }} -->
          <!-- Invoice Description: Table -->
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  SL
                </th>
                <th scope="col">
                  Description
                </th>
                <th scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoiceData"
                :key="invoice.id"
              >
                <th scope="row">
                  {{ invoice.id }}
                </th>
                <td>{{ invoice.description }}</td>
                <td>{{ invoice.amount }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Total
                </th>
                <th scope="col">
                  12835.00
                </th>
              </tr>
            </tfoot>
          </table>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <div class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <p
              :style="{lineHeight:'5px',marginTop:'5px'}"
            >
              <small>
                1. Please pay your tuition fees to Padma Bank Bogra.
              </small>
            </p>
            <p :style="{lineHeight:'5px'}">
              <small>
                2. Equivalent amount of one month fee will be added as readmission fee after
                last day of payment date
              </small>
            </p>
          </div>
          <!-- Invoice Description: Total -->
          <div class="invoice-padding pb-0 mt-5">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <div class="mb-0">
                  <span class="font-weight-bold">Print Data:</span>
                  <span class="ml-75">: 07-07-2021</span>
                </div>
                <div class="mb-0">
                  <span class="font-weight-bold">Printed By</span>
                  <span class="ml-75">:  2001254785</span>
                </div>
                <div class="mb-0">
                  <span class="font-weight-bold">Powered BY</span>
                  <span class="ml-75">:  fitl.com.bd</span>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper border-dark text-center">
                  <p>(Signature)</p>
                </div>
              </b-col>
            </b-row>
          </div>

        </div>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="generatePdf"
          >
            Download
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
        </b-card>
      </b-col>

    </b-row>
    <APBNComponent
      ref="apbnInvoice"
      :invoice-data="invoiceData"
      :apbn-logo="apbnLogo"
    />
  </section>
</template>
<script>
import {
  BRow, BCol, BCard,
  BButton,
  VBToggle, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import APBNComponent from '@/views/apps/invoice/invoice-preview/APBNComponent.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
    APBNComponent,
  },

  data() {
    return {
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      // eslint-disable-next-line global-require
      apbnLogo: require('@/assets/images/logo/apbn-logo.png'),
      pageData: {},
      isLoading: false,
      isLoaddingPreloader: true,
      invoiceData: [
        { id: '1', description: 'Previous Dues (Jan-Feb)', amount: '5835.00' },
        { id: '2', description: 'Monthly Fee (Mar) ', amount: '800.00' },
        { id: '3', description: 'Advance Payment (Apr)', amount: '5000.00' },
        { id: '4', description: 'Admission Fee', amount: '2000.00' },
        { id: '5', description: 'Transport Fee (Mar)', amount: '400.00' },
        { id: '6', description: 'Transfer Certificate Fee', amount: '700.00' },
        { id: '7', description: 'Development Fee (Mar)', amount: '250.00' },
        { id: '8', description: 'Late Fee ', amount: '250.00' },
        { id: '9', description: 'Absent Fine ', amount: '100.00' },
        { id: '10', description: 'Board Exam Fee ', amount: '150.00' },
        { id: '11', description: 'Session Fee ', amount: '500.00' },
        { id: '12', description: 'ID Card Fee, Shoulder Badge, Nameplate', amount: '500.00' },
        { id: '13', description: 'Library Fee, Library Deposit', amount: '250.00' },
        { id: '14', description: 'Electricity Fee, Generator Fee', amount: '250.00' },
        { id: '15', description: 'Dairy, Magazine Fee, Printing Fee', amount: '350.00' },
        { id: '16', description: 'Software Fee, Multimedia Fee', amount: '1500.00' },
        { id: '17', description: 'Education Board Fee', amount: '800.00' },
        { id: '18', description: 'Sports Fee, Culture Fee', amount: '2500.00' },
        { id: '19', description: 'Common Room Fee', amount: '1500.00' },
        { id: '20', description: 'Medical Fee', amount: '500.00' },
        { id: '21', description: 'Milad Fee', amount: '350.00' },
        { id: '22', description: 'Others Fee', amount: '250.00' },
      ],
    }
  },
  methods: {
    generatePdf() {
      this.$refs.apbnInvoice.generatePdf()
    },

    printInvoice() {
      this.$refs.apbnInvoice.printInvoice()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
